import React from "react"
import { Fade } from "react-awesome-reveal"
import styled from "styled-components"
import studioImage1 from "../assets/images/05_STUDIO_carousel/studio_1.jpg"
import studioImage2 from "../assets/images/05_STUDIO_carousel/studio_2.jpg"
import studioImage3 from "../assets/images/05_STUDIO_carousel/studio_3.jpg"
import studioImage5 from "../assets/images/05_STUDIO_carousel/studio_5.jpg"
import studioImage6 from "../assets/images/05_STUDIO_carousel/studio_6.jpg"
import SEO from "../components/SEO"

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "../styles/sliderStyler.css"

const PageStyles = styled.div`
  /* scroll-snap-type: y mandatory;
  overflow-y: scroll; */
  height: calc(100vh - 150px);
`

const ImageStyles = styled.div`
  /* scroll-snap-align: start; */
  background-size: cover;
  height: calc(100vh - 150px);
`

const BodyStyles = styled.div`
  /* scroll-snap-align: start; */
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 130px;

  @media (max-width: 900px) {
    padding-left: 90px;
    padding-right: 40px;
    padding-bottom: 120px;
    padding-top: 45px;
    min-height: 0;
  }

  h1 {
    margin-left: -65px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  article {
    columns: 2;
    column-width: 330px;
    column-gap: 30px;
  }
`

const StudioPage = () => {
  const [pause, setPause] = React.useState(false)
  const timer = React.useRef()
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    duration: 1000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  })

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [sliderRef])

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 2000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <>
      <SEO title="Studio" image={studioImage1} />
      <Fade triggerOnce>
        <PageStyles>
          <div ref={sliderRef} className="keen-slider">
            <div
              className="keen-slider__slide number-slide1"
              style={{
                backgroundImage: `url(${studioImage1})`,
              }}
            ></div>
            <div
              className="keen-slider__slide number-slide2"
              style={{ backgroundImage: `url(${studioImage2})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide3"
              style={{ backgroundImage: `url(${studioImage3})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide4"
              style={{ backgroundImage: `url(${studioImage5})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide5"
              style={{ backgroundImage: `url(${studioImage6})` }}
            ></div>
          </div>
          <BodyStyles>
            <div>
              <h1>STUDIO</h1>

              <article>
                <p>
                  As a musician or content creator, you need a dedicated space
                  to work and we’ve got you covered with our air conditioned and
                  sound treated studio. The studio has heaps of natural light
                  and views of jungle and coconut trees. Our beautiful space is
                  the perfect place to work on your project or for your band to
                  get away and write some new songs.
                </p>
                <p>
                  Verse Studios is run by our resident artist, engineer and
                  producer, Josh Klynsmith. Josh was the drummer for MTV MAMA
                  award winning band, Gangs Of Ballet who shared a stage with
                  the likes of Switchfoot, One Republic, Foals, Biffy Clyro,
                  Everything Everything, Civil Twilight, Of Monsters And Men,
                  and Foster The People to name a few, we are so blessed to have
                  someone of his calibre at verse.
                </p>
                <p>
                  Definitely don’t miss your opportunity to work in our
                  inspiring space or work with Josh on your project. Session
                  musicans, mixing and mastering are all possible, contact us
                  directly to get a quote tailored to your specific needs or
                  book a package directly from our booking page.
                </p>
              </article>
            </div>
          </BodyStyles>
        </PageStyles>
      </Fade>
    </>
  )
}

export default StudioPage
